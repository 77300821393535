import { VCombobox } from 'vuetify/lib'

export default {
  name: 'CustomCombobox',
  extends: VCombobox,
  methods: {
    selectItem(item) {
      // itemがstringの場合は、代入しない
      if (typeof item === 'string') {
        return
      }

      this.internalValue = this.multiple ? [...this.internalValue, item] : item
      this.$emit('input', this.internalValue)
      this.$emit('change', this.internalValue)
    },
  },
}
