var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"dataTable",staticClass:"cursor-pointer",attrs:{"loading":_vm.isLoading,"headers":_vm.ReceiptHeaders,"items":_vm.receipts,"header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"no-data-text":"データがありません","page":_vm.$route.query.page ? Number(_vm.$route.query.page) : 1},on:{"update:page":function ($event) {
      _vm.$vuetify.goTo(_vm.$refs.dataTable)
      _vm.addQuery({ page: $event })
    }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table"}})]},proxy:true},{key:"top",fn:function(){return [_c('div',{staticClass:"w-full d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.isLoading,"disabled":_vm.receipts.length <= 0 || _vm.isLoading,"ripple":false,"color":"secondary","small":""},on:{"click":_vm.download}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileExcel)+" ")]),_vm._v(" ダウンロード ")],1)],1)]},proxy:true},{key:"item.meta.realTotal",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.meta.realTotal ? ("¥" + (item.meta.realTotal.toLocaleString())) : '¥0')+" ")]}},{key:"item.attributes.subtotal",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.subtotal ? ("¥" + (item.attributes.subtotal.toLocaleString())) : '¥0')+" ")]}},{key:"item.attributes.serviceFee",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.serviceFee ? ("¥" + (item.attributes.serviceFee.toLocaleString())) : '¥0')+" ")]}},{key:"item.attributes.processingFee",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.processingFee ? ("¥" + (item.attributes.processingFee.toLocaleString())) : '-')+" ")]}},{key:"item.attributes.discountFee",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.discountFee ? ("¥" + (item.attributes.discountFee.toLocaleString())) : '¥0')+" ")]}},{key:"item.meta.totalCustomerPayAmount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.meta.totalCustomerPayAmount ? ("¥" + (item.meta.totalCustomerPayAmount.toLocaleString())) : '¥0')+" ")]}},{key:"item.attributes.change",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:Number(item.attributes.change) < 0 ? 'error--text' : ''},[_vm._v(" "+_vm._s(("¥" + (item.attributes.change.toLocaleString())))+" ")])]}},{key:"item.attributes.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.dateTime(item.attributes.createdAt)))])]}},{key:"item.meta.hasIssuedReceiptSlipt",fn:function(ref){
    var item = ref.item;
return [(item.meta.hasIssuedReceiptSlipt)?_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning","small":""}},[_vm._v(" 発行済 ")]):_vm._e()]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(item.id)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-receipt', item.id)}}},[(_vm.isLoadingReceipt && +_vm.receipt.id === +item.id)?_c('clip-loader',{staticClass:"d-flex align-items pr-2",attrs:{"loading":true,"color":'#8A8D93',"size":"16px"}}):_vm._e(),_vm._v(" 伝票 No."+_vm._s(item.id)+" ")],1):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" / "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }